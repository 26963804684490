import { graphql, PageProps } from 'gatsby';
import React, { useMemo } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Layout } from '../components/Layout';
import { ProjectGrid } from '../components/ProjectGrid';
import { linkResolver } from '../core-ui/utils/linkResolver';
import {
  ProjectFragment,
  ProjectsByCategoryQuery,
  ProjectCategoryFragment,
} from '../__generated__/types';

type Props = {
  data: ProjectsByCategoryQuery;
  path: string;
};

export const POST_PER_PAGE = 6;

const ProjectsByCategory = (props: Props) => {
  const {
    data: {
      allPrismicProject: { totalCount, nodes: prismicProjects },
      allPrismicProjectCategory: { edges: projectCategories },
      prismicPage,
    },
  } = props;

  const projectList = useMemo(() => {
    return (prismicProjects
      ?.map((item) => {
        if (item != null) {
          return item;
        }
      })
      .filter((item) => item != null) ?? []) as Array<ProjectFragment>;
  }, [prismicProjects]);

  const projectCategoryFilter = (projectCategories
    ?.map((item) => {
      if (item != null) {
        return item.node;
      }
    })
    .filter((item) => item != null) ?? []) as Array<ProjectCategoryFragment>;
  const [firstUrlSegment, secondUrlSegment] =
    props.path
      .split('/')
      .filter((item) => item !== '' && item !== 'projects') || [];

  const selectedFilterId = Number.isNaN(Number(firstUrlSegment))
    ? firstUrlSegment
    : null;

  const currentPage = Number.isNaN(Number(firstUrlSegment))
    ? Number(secondUrlSegment)
    : Number(firstUrlSegment);
  return (
    <Layout title="Custom Homes">
      {/* TODO: Create custom page for this? */}
      <ProjectGrid
        projects={projectList}
        projectCategory={projectCategoryFilter}
        last
        sectionTitle={prismicPage?.data.title?.text}
        sectionSubtitle={prismicPage?.data.description?.richText}
        selectedFilterId={selectedFilterId ?? undefined}
        totalPage={Math.ceil(totalCount / POST_PER_PAGE)}
        currentPage={currentPage}
        pathPrefix={`/projects${
          selectedFilterId ? `/${selectedFilterId}` : ''
        }`}
      />
    </Layout>
  );
};

export const query = graphql`
  query ProjectsByCategory(
    $limit: Int!
    $skip: Int!
    $filter: PrismicProjectFilterInput
  ) {
    allPrismicProject(
      sort: { order: [DESC], fields: [first_publication_date] }
      limit: $limit
      skip: $skip
      filter: $filter
    ) {
      totalCount
      nodes {
        ...Project
      }
    }
    prismicPage(uid: { eq: "projects" }) {
      _previewable
      id
      uid
      data {
        title {
          text
        }
        description {
          richText
          text
        }
        display_site_meta
      }
    }
    allPrismicProjectCategory {
      edges {
        node {
          _previewable
          id
          ...ProjectCategory
        }
      }
    }
  }
`;

export default withPrismicPreview<Props['data'], PageProps<Props['data']>>(
  ProjectsByCategory,
  [
    {
      repositoryName: 'scandidesign',
      linkResolver: linkResolver,
    },
  ],
);
